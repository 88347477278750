import { Switch, Route } from 'react-router-dom';

import LandingContainer from 'apps/kiosk/containers/Validation/LandingContainer';
import MissingShortCodeContainer from 'apps/kiosk/containers/Validation/MissingShortCodeContainer';

export default function ValidationKiosk() {
  return (
    <Switch>
      <Route exact path="/">
        <MissingShortCodeContainer />
      </Route>
      <Route path="/:shortCode">
        <LandingContainer />
      </Route>
    </Switch>
  );
}
