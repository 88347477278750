import styled from 'styled-components';

export const ValidationInfoBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  padding-top: 2px;

  & svg {
    margin-bottom: -2px;
    margin-right: 4px;
  }
`;
