import { useEffect, useRef, useState } from 'react';

import { Portal } from 'react-portal';
import Keyboard from 'react-simple-keyboard';

import {
  KeyboardContainer,
  KeyboardAspectRatio,
  KeyboardStyler,
} from './LicensePlateKeyboard.styled';

import type { KeyboardReactInterface } from 'react-simple-keyboard';

type LicensePlateKeyboardProps = KeyboardReactInterface['options'] & {
  active?: boolean;
  submitEnabled?: boolean;
};

const defaultDisplay = {
  '{enter}': 'submit',
  '{backspace}': '⌫',
};
const defaultLayout = {
  default: [
    '1 2 3 4 5 6 7 8 9 0 {backspace}',
    'q w e r t y u i o p',
    'a s d f g h j k l',
    'z x c v b n m {enter}',

    // These rows are hidden visually but are here
    // to allow hardware key input when shift or
    // caps-lock are enabled
    'Q W E R T Y U I O P',
    'A S D F G H J K L',
    'Z X C V B N M',
  ],
};

export function LicensePlateKeyboard({
  active,
  submitEnabled,
  ...keyboardProps
}: LicensePlateKeyboardProps) {
  const keyboardRef = useRef<KeyboardReactInterface>();
  const [initialRevealComplete, setInitialRevealComplete] = useState(false);

  // remove keyboard event listeners on unmount
  // @ts-ignore
  useEffect(() => () => keyboardRef.current?.destroy(), []);

  return (
    <Portal>
      <KeyboardContainer
        active={active}
        initialRevealComplete={initialRevealComplete}
        onAnimationEnd={() => setInitialRevealComplete(true)}
      >
        <KeyboardAspectRatio>
          <KeyboardStyler
            active={active}
            submitEnabled={submitEnabled}
            initialRevealComplete={initialRevealComplete}
          >
            <Keyboard
              keyboardRef={(kb: KeyboardReactInterface) => {
                keyboardRef.current = kb;
              }}
              preventMouseUpDefault
              autoUseTouchEvents={false}
              enableLayoutCandidates={false}
              disableButtonHold
              disableCaretPositioning
              stopMouseUpPropagation
              stopMouseDownPropagation
              physicalKeyboardHighlight
              physicalKeyboardHighlightPress
              layout={defaultLayout}
              display={defaultDisplay}
              {...keyboardProps}
            />
          </KeyboardStyler>
        </KeyboardAspectRatio>
      </KeyboardContainer>
    </Portal>
  );
}
