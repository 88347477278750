import styled, { keyframes } from 'styled-components';

import { ReactComponent as ErrorIcon } from 'apps/kiosk/assets/svg/error.svg';

export const IdleMessageBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  max-width: 592px;
  height: 100%;
  padding: 0 24px;
  border-radius: inherit;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 8px;
    left: 8px;
    bottom: 8px;
    width: 4px;
    border-radius: 2px;
    background-color: #dba10e;
  }
`;

const progress = keyframes`
  0% {
    transform: scale3d(0, 1, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
`;

export const IdleProgressBar = styled.div<{ show: boolean; duration: number }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background-color: rgba(219, 161, 14, 0.12);
  transform-origin: 0 0;
  animation-name: ${({ show }) => (show ? progress : '')};
  animation-timing-function: linear;
  animation-delay: 250ms;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  animation-duration: ${({ duration }) => duration}s;
`;

export const IdleMessage = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
`;
export const IdleMessageText = styled.div`
  font-size: 18px;
  line-height: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.grey7};
  letter-spacing: 0.02em;
`;

export const CountdownMessage = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.grey7};
  line-height: 16px;
  margin-top: 8px;
  margin-bottom: 3px;
`;

export const ExtendTimeButton = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  height: 40px;
  padding: 0 16px;
  padding-bottom: 1px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.03em;
  color: ${({ theme }) => theme.colors.grey1};
  background-color: #111110;
  border-radius: 10px;
`;

export const WarningIcon = styled(ErrorIcon)`
  margin-left: 4px;
  margin-right: 16px;
`;
