import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';

import storage from 'local-storage-fallback';
import throttle from 'lodash/throttle';
import { useHistory, useParams } from 'react-router-dom';

import { IdleBanner, PageBox, SlideUpModal } from 'apps/kiosk/components';
import {
  AlreadyValidated,
  CantFindVisit,
  DontKnowPlate,
  LandingScreen,
  SuccessScreen,
} from 'apps/kiosk/components/Validation';
import { VALIDATION_SHORT_CODE_STORAGE_KEY } from 'apps/kiosk/constants';
import PlateInputContainer from 'apps/kiosk/containers/Validation/PlateInputContainer';
import { ValidationService } from 'apps/kiosk/services';
import Countdown from 'components/Countdown';
import { heapAddEventProperties, heapTrackProperties } from 'utils/heap';

import type { KioskValidationDetail, ValidationFailureReason } from 'apps/kiosk/types';

// Show banner after 10 seconds
const INITIAL_IDLE_TIMEOUT = 10;
const EXTEND_TIME_MULTIPLIER = 1.5;

export default function LandingContainer() {
  const history = useHistory();
  const { shortCode } = useParams<{ shortCode: string }>();

  const [validationInfo, setValidationInfo] = useState<KioskValidationDetail | null>(null);
  const [showUnknownPlate, setShowUnknownPlate] = useState(false);
  const [showNoVisit, setShowNoVisit] = useState(false);
  const [showAlreadyValidated, setShowAlreadyValidated] = useState(false);
  const [submittedPlate, setSubmittedPlate] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showPlateInput, setShowPlateInput] = useState(false);
  const [validationSuccess, setValidationSuccess] = useState<boolean | null>(null);
  const [idleTimeout, setIdleTimeout] = useState(INITIAL_IDLE_TIMEOUT);
  const [showIdleBanner, setShowIdleBanner] = useState(false);

  const renderIdleCountdown = showUnknownPlate || showNoVisit || showAlreadyValidated || showModal;

  const disableScroll = showUnknownPlate || showNoVisit || showAlreadyValidated;

  const openUnknownPlateModal = useCallback(() => {
    setShowUnknownPlate(true);
    heapTrackProperties("Open: I don't know my license plate", {
      'Date/Time (UTC)': new Date().toUTCString(),
      timestamp: Date.now(),
    });
  }, []);
  const closeUnknownPlateModal = useCallback(() => {
    setShowUnknownPlate(false);
    heapTrackProperties("Close: I don't know my license plate", {
      'Date/Time (UTC)': new Date().toUTCString(),
      timestamp: Date.now(),
    });
  }, []);

  const openModal = useCallback(() => {
    setValidationSuccess(null);
    setShowModal(true);
    setShowPlateInput(true);
  }, []);

  const closeModal = useCallback(() => {
    setShowModal(false);
    setTimeout(() => {
      setShowPlateInput(false);
    }, 350);
  }, []);

  const resetIdleTimeout = useMemo(
    // Using throttle to prevent duplicate calls
    // if both mouse/touch events fire
    () =>
      throttle(
        () => {
          // The `<Countdown />` component is assigned
          // a `key` prop referencing the duration
          // This forces the component to re-render
          // in a fresh state, having the effect
          // of resetting the idle timer
          setIdleTimeout(Infinity);
          setIdleTimeout(INITIAL_IDLE_TIMEOUT);
        },
        5000,
        { leading: true, trailing: true },
      ),
    [],
  );

  const extendIdleTimeout = useCallback(() => {
    if (showIdleBanner) {
      setIdleTimeout((timeout) => Math.round(timeout * EXTEND_TIME_MULTIPLIER));
      setShowIdleBanner(false);
    }
  }, [showIdleBanner]);

  const resetToInitialLanding = useCallback(() => {
    setIdleTimeout(INITIAL_IDLE_TIMEOUT);
    setShowIdleBanner(false);
    setShowUnknownPlate(false);
    setShowNoVisit(false);
    setShowAlreadyValidated(false);
    closeModal();
  }, [closeModal]);

  const handleSuccess = useCallback(() => setValidationSuccess(true), []);
  const handleFailure = useCallback((plateText: string, reason: ValidationFailureReason) => {
    setSubmittedPlate(plateText);
    if (reason.cantFindVisit) {
      setShowNoVisit(true);
    } else if (reason.alreadyValidated) {
      setShowAlreadyValidated(true);
    }
    setValidationSuccess(false);
  }, []);

  useEffect(() => {
    if (!renderIdleCountdown && idleTimeout !== INITIAL_IDLE_TIMEOUT) {
      setIdleTimeout(INITIAL_IDLE_TIMEOUT);
    }
  }, [idleTimeout, renderIdleCountdown]);

  useEffect(() => {
    async function getValidationInfo() {
      const { success, data } = await ValidationService.getValidationDetails(shortCode);
      if (success) {
        setValidationInfo(data);
        storage.setItem(VALIDATION_SHORT_CODE_STORAGE_KEY, shortCode);
      } else {
        storage.removeItem(VALIDATION_SHORT_CODE_STORAGE_KEY);
        history.replace('/');
      }
    }
    getValidationInfo();
    heapAddEventProperties({ 'Short Code': shortCode });
  }, [history, shortCode]);

  useEffect(() => {
    if (validationInfo) {
      const { name, validationTerms } = validationInfo;
      heapAddEventProperties({
        'Validation Name': name,
        'Validation Terms': validationTerms,
      });
    }
  }, [validationInfo]);

  useLayoutEffect(() => {
    if (renderIdleCountdown) {
      window.addEventListener('mousemove', resetIdleTimeout);
      window.addEventListener('touchstart', resetIdleTimeout);
    }
    return () => {
      window.removeEventListener('mousemove', resetIdleTimeout);
      window.removeEventListener('touchstart', resetIdleTimeout);
    };
  }, [resetIdleTimeout, renderIdleCountdown]);

  return (
    <>
      <IdleBanner
        show={showIdleBanner}
        onUserInteract={extendIdleTimeout}
        onIdleTimeout={resetToInitialLanding}
      />
      <PageBox position={disableScroll ? 'fixed' : undefined}>
        <LandingScreen
          validation={validationInfo}
          onClickValidate={openModal}
          onClickUnknownPlate={openUnknownPlateModal}
        />
      </PageBox>
      <SlideUpModal
        show={showModal}
        hideCloseButton={validationSuccess !== null}
        onCancel={closeModal}
      >
        {showPlateInput && (
          <PlateInputContainer
            shortCode={shortCode}
            validationInfo={validationInfo}
            active={showModal && showPlateInput && !showUnknownPlate}
            completed={validationSuccess}
            onSuccess={handleSuccess}
            onFailure={handleFailure}
            onClickUnknownPlate={openUnknownPlateModal}
          />
        )}
        {validationSuccess && <SuccessScreen validation={validationInfo} onDismiss={closeModal} />}
      </SlideUpModal>
      <DontKnowPlate show={showUnknownPlate} onCancel={closeUnknownPlateModal} />
      <CantFindVisit
        show={showNoVisit}
        onCancel={() => {
          setShowNoVisit(false);
          setTimeout(() => closeModal(), 300);
        }}
        onEditPlate={() => {
          setValidationSuccess(null);
          setShowNoVisit(false);
        }}
        licensePlateText={submittedPlate}
      />
      <AlreadyValidated
        show={showAlreadyValidated}
        onCancel={() => {
          setShowAlreadyValidated(false);
          setTimeout(() => closeModal(), 300);
        }}
      />
      {renderIdleCountdown && !showIdleBanner && (
        <Countdown
          key={`idle-countdown__${idleTimeout}`}
          duration={idleTimeout}
          onComplete={() => setShowIdleBanner(true)}
        />
      )}
    </>
  );
}
