import Providers from 'providers';

import { GlobalStyles } from './Kiosk.styled';
import ValidationKiosk from './ValidationKiosk';

export function ValidationApp() {
  return (
    <Providers>
      <ValidationKiosk />
      <GlobalStyles />
    </Providers>
  );
}
