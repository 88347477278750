import styled, { keyframes } from 'styled-components';

const keyboardReveal = keyframes`
  0% {
    transform: translate3d(0,125%,0);
  }
  100% {
    transform: translate3d(0,0,0);
  }
`;

type KeyboardContainerProps = {
  active?: boolean;
  initialRevealComplete?: boolean;
  submitEnabled?: boolean;
};

function getTransition({ active, initialRevealComplete }: KeyboardContainerProps) {
  if (!initialRevealComplete) {
    return 'none';
  }
  if (active) {
    return 'transform 350ms ease 300ms';
  }
  return 'transform 300ms ease 0ms';
}

export const KeyboardContainer = styled.div<KeyboardContainerProps>`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding-top: 10px;
  padding-bottom: 6px;
  padding-left: 28px;
  padding-right: 44px;
  overflow: hidden;
  pointer-events: ${({ active }) => (active ? 'auto' : 'none')};

  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(200, 200, 205, 0.675);

    border-radius: 0;
    backdrop-filter: blur(36px);
    transform: translate3d(0, ${({ active }) => (active ? '0' : '100%')}, 0);
    transition: ${getTransition};
    animation: ${keyboardReveal} 350ms ease 300ms 1 backwards;
  }

  @media (min-height: 700px) {
    padding-top: 24px;
  }

  @media (min-height: 800px) {
    padding-top: 32px;
    padding-bottom: 24px;
  }

  @media (min-height: 900px) {
    padding-top: 48px;
  }
`;

export const KeyboardAspectRatio = styled.div`
  position: relative;
  width: 100%;
  max-width: 360px;
  max-height: 100%;
  flex-shrink: 1;
  min-height: 180px;

  @media (min-width: 500px) {
    max-width: 440px;
    min-height: 200px;
  }

  @media (min-width: 600px) {
    max-width: 520px;
    min-height: 224px;
  }

  @media (min-width: 700px) {
    max-width: 600px;
    margin-left: -90px;
  }

  @media (min-width: 800px) {
    max-width: 720px;
    margin-left: -60px;
  }

  @media (min-width: 900px) {
    max-width: 800px;
    margin-left: -96px;
  }

  @media (min-width: 1100px) {
    max-width: 840px;
    margin-left: -108px;
  }

  @media (min-height: 700px) {
    min-height: 300px;
  }

  @media (min-height: 800px) {
    min-height: 350px;
  }

  @media (min-height: 900px) {
    min-height: 360px;
  }
`;

export const KeyboardStyler = styled.div<KeyboardContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .simple-keyboard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;
    height: 100%;

    transform: translate3d(0, ${({ active }) => (active ? '0' : '125%')}, 0);
    transition: ${getTransition};
    animation: ${keyboardReveal} 350ms ease 300ms 1 backwards;
  }

  .simple-keyboard .hg-rows {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
    flex: 1;
    width: 100%;
    height: 100%;
  }

  .simple-keyboard .hg-row {
    display: grid;
    flex: 1;
    width: 100%;
    grid-template-columns: repeat(10, 1fr);
    column-gap: min(1.25vmin, 12px);
    position: relative;
    place-items: stretch;
    margin-bottom: min(1.25vmin, 12px);

    &:nth-child(1) {
      & > .hg-standardBtn {
        transform: translate3d(-100%, 0, 0);
      }
    }
    &:nth-child(2) {
      & > .hg-standardBtn {
        transform: translate3d(-50%, 0, 0);
      }
    }
    &:nth-child(4) {
      & > .hg-standardBtn {
        transform: translate3d(50%, 0, 0);
      }
      & ~ * {
        display: none !important;
      }
    }

    @media (max-width: 440px) {
      column-gap: 3px;
      margin-bottom: 4px;
    }
  }

  .simple-keyboard .hg-button {
    box-sizing: border-box;
    padding: 0;
    border-radius: 3px;
    background-color: #fff;
    box-shadow:
      inset 0 -1px 0 rgba(0, 0, 0, 0.3),
      0 0 1px rgba(0, 0, 0, 0.125);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: max(min(3.75vmin, 26px), 17px);
    line-height: 1;
    width: 100%;
    flex: 1;
    align-self: stretch;
    justify-self: stretch;
    flex-grow: 0;
    user-select: none;
    text-transform: uppercase;
    transition:
      background-color 100ms linear 0ms,
      opacity 150ms linear 0ms;

    &.hg-button-backspace {
      position: absolute;
      top: 0;
      right: calc(-12.5% - (min(1vw, 12px) * 2));
      width: 18%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.15);
      font-size: max(min(4.5vw, 34px), 24px);
      font-weight: 300;
      max-width: none;
      box-shadow:
        inset 0 -1px 0 rgba(0, 0, 0, 0.3),
        0 0 1px rgba(0, 0, 0, 0.1);

      &:active {
        background-color: rgba(0, 0, 0, 0.25);
      }
    }

    &.hg-button-enter {
      position: absolute;
      bottom: 0;
      right: calc(-12.5% - (min(1vw, 12px) * 2));
      width: 18%;
      height: calc(300% + (min(1.25vmin, 12px) * 2));
      background-color: ${({ theme }) => theme.colors.blue6};
      color: ${({ theme }) => theme.colors.grey1};
      pointer-events: ${({ submitEnabled }) => (submitEnabled ? 'auto' : 'none')};
      opacity: ${({ submitEnabled }) => (submitEnabled ? 1 : 0.35)};
      text-transform: lowercase;
      align-items: flex-end;
      padding-bottom: min(3vh, 12px);
      &:active {
        background-color: ${({ theme }) => theme.colors.blue7};
        color: rgba(255, 255, 255, 0.5);
      }

      @media (max-width: 440px) {
        height: calc(300% + 8px);
      }
    }

    &:active {
      background-color: rgba(0, 0, 0, 0.075);
      box-shadow: none !important;
      transition: none !important;
    }

    @media (min-width: 600px) and (min-height: 900px) {
      font-size: min(3.2vmin, 28px);
    }

    @media (min-width: 700px) and (min-height: 700px) {
      border-radius: 6px;
    }
  }
`;
