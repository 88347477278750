import { useCallback, useEffect, useState } from 'react';
import type { FormEvent } from 'react';

import { ReactComponent as ArrowIcon } from 'apps/kiosk/assets/svg/arrow-forward.svg';

import { LicensePlateKeyboard } from './LicensePlateKeyboard';
import {
  Form,
  PlateValue,
  InputCursor,
  ErrorMessage,
  HelpLink,
  SubmitButton,
  LoadingBox,
  LoadingSpinner,
} from './PlateForm.styled';

const KEY_ENTER = '{enter}';
const KEY_DELETE = '{bksp}';

type PlateFormProps = {
  active?: boolean;
  loading?: boolean;
  submitted?: boolean;
  onSubmit: (plateText: string) => void;
  onClickUnknownPlate: () => void;
};
export function PlateForm({
  active,
  loading,
  submitted,
  onSubmit,
  onClickUnknownPlate,
}: PlateFormProps) {
  const [plateText, setPlateText] = useState('');
  const [maxLenErr, setMaxLenErr] = useState(0);
  const maxLengthReached = plateText.length === 8;
  const canSubmit = plateText.length >= 3;

  const handleChange = useCallback(
    (text: string) => {
      if (maxLenErr && text.length < 8) {
        setMaxLenErr(0);
      }
      setPlateText(text.toUpperCase());
    },
    [maxLenErr],
  );

  const handleKeyPress = useCallback(
    (key: string, e: MouseEvent | undefined) => {
      if (maxLengthReached && key !== KEY_ENTER && key !== KEY_DELETE) {
        setMaxLenErr((prev) => prev + 1);
      }
      if (key === KEY_ENTER && canSubmit) {
        e?.preventDefault();
        e?.stopPropagation();
        onSubmit(plateText);
      }
    },
    [canSubmit, maxLengthReached, onSubmit, plateText],
  );

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      onSubmit(plateText);
    },
    [onSubmit, plateText],
  );

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (maxLenErr) {
      // dismiss error message after 3 seconds
      timeoutId = setTimeout(() => setMaxLenErr(0), 3000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [maxLenErr]);

  return (
    <Form onSubmit={handleSubmit}>
      <PlateValue isLoading={loading}>
        {plateText}
        <InputCursor
          key={`cursor-anim-${maxLenErr}`}
          error={Boolean(maxLenErr)}
          isLoading={loading}
        />
        <SubmitButton
          show={canSubmit}
          isLoading={loading}
          disabled={!canSubmit || loading || submitted}
        >
          <LoadingBox isLoading={loading}>
            <LoadingSpinner />
          </LoadingBox>
          {!submitted && <ArrowIcon className="arrow-next" />}
        </SubmitButton>
        <ErrorMessage show={Boolean(maxLenErr)}>Maximum length is 8 characters</ErrorMessage>
      </PlateValue>
      <HelpLink inactive={loading} onClick={onClickUnknownPlate}>
        I don&apos;t know my license plate number
      </HelpLink>
      <LicensePlateKeyboard
        active={active && !loading && !submitted}
        onChange={handleChange}
        maxLength={12}
        onKeyPress={handleKeyPress}
        submitEnabled={canSubmit}
        physicalKeyboardHighlight
        physicalKeyboardHighlightPress
      />
    </Form>
  );
}
