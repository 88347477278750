import { AlertModal } from 'apps/kiosk/components/Modals/AlertModal';
import {
  ModalHeadingLarge,
  MessageText,
  ModalButton,
} from 'apps/kiosk/components/Modals/Modals.styled';

import { MessageBox, MessageBold, MessageBullet } from './DontKnowPlate.styled';

type DontKnowPlateProps = {
  show?: boolean;
  onCancel?: () => void;
};

export function DontKnowPlate({ show, onCancel }: DontKnowPlateProps) {
  return (
    <AlertModal show={show} onCancel={onCancel}>
      <ModalHeadingLarge>
        What if I don&apos;t know my
        <br />
        license plate?
      </ModalHeadingLarge>
      <MessageBox>
        <MessageText>
          We are unable to validate your parking visit without your license plate.
        </MessageText>
        <MessageText>
          You can return to this kiosk when you have your license plate and try again before you
          leave.
        </MessageText>
        <MessageBold>To avoid this on your next visit, you can:</MessageBold>
        <MessageBullet>
          Take a photo of your license plate with your cell phone camera so that you have it on hand
        </MessageBullet>
        <MessageBullet>
          Scan one of the QR codes at this location to sign up for a Metropolis account. Then, never
          wait in line again to pay for parking.
        </MessageBullet>
      </MessageBox>
      <ModalButton onClick={onCancel} backgroundColor="blue6" color="grey1">
        Got it
      </ModalButton>
    </AlertModal>
  );
}
