import styled, { css } from 'styled-components';

const headingStyle = css`
  font-size: 32px;
  font-weight: 700;
  text-indent: 2px;
  margin: 0;
  line-height: 1;

  @media (min-width: 700px) {
    font-size: 40px;
  }
`;
export const Heading = styled.h1`
  ${headingStyle}
  margin-top: 24px;
  color: ${({ theme }) => theme.colors.grey7};

  @media (min-height: 700px) {
    margin-top: 32px;
  }
`;

export const SubHeading = styled.h2`
  ${headingStyle}
  margin-top: 8px;
  max-width: 557px;
  white-space: nowrap;
  overflow: visible;
  color: ${({ theme }) => theme.colors.blue6};
`;

export const PrimaryMessage = styled.p`
  font-size: 24px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 20px;
  margin-bottom: 60px;
  line-height: 1.2;
  text-indent: 2px;
`;

export const HelpLink = styled.a`
  font-size: 24px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  margin-top: 36px;
  align-self: center;

  &:hover {
    color: rgba(0, 0, 0, 0.8);
  }
  &:active {
    color: ${({ theme }) => theme.colors.blue6};
  }
`;
