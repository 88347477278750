import styled from 'styled-components';

export const InputContainerBox = styled.div<{ completed?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 24px;
  padding-top: 36px;
  opacity: ${({ completed }) => (completed ? 0 : 1)};
  transform: translate3d(0, ${({ completed }) => (completed ? '6vh' : '0')}, 0)
    scale3d(
      ${({ completed }) => (completed ? 0.875 : 1)},
      ${({ completed }) => (completed ? 0.875 : 1)},
      1
    );
  transition:
    opacity 300ms linear 0ms,
    transform 350ms ease-in-out 0ms;

  @media (min-height: 700px) {
    padding-top: 48px;
  }

  @media (min-width: 700px) {
    padding-left: 48px;
    padding-right: 48px;
  }

  @media (min-height: 1000px) {
    padding-top: 5vh;
  }
`;
