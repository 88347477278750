import styled, { keyframes } from 'styled-components';

import { PageButton } from 'apps/kiosk/components/Button';
import { ModalHeading } from 'apps/kiosk/components/Modals';
import { fadeIn, fadeInPop, slideUpFadeIn } from 'styled/keyframes';
import { absoluteFill } from 'styled/mixins';

const borderReveal = keyframes`
  0% {
    transform: scale3d(1.2,1.2,1);
  }
  100% {
    transform: scale3d(1,1,1);
  }
`;
const borderGlowReveal = keyframes`
  0% {
    opacity: 0.35;
    transform: scale3d(9,9,1);
  }
  100% {
    opacity: 0;
    transform: scale3d(1.25,1.25,1);
  }
`;
export const SuccessBorder = styled.div`
  ${absoluteFill}
  z-index: 0;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  overflow: hidden;

  &::before {
    content: '';
    ${absoluteFill}
    border: 16px solid #00CB96;
    animation: ${borderReveal} 600ms ease 300ms 1 backwards;
  }
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    margin-top: -50%;
    padding-bottom: 100%;
    box-shadow: inset 0 0 0 35vmax #00cb96;
    border-radius: 50%;

    animation: ${borderGlowReveal} 550ms linear 650ms 1 both;
  }
`;
export const CheckIconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 61px;
  height: 61px;
  border-radius: 50%;
  margin-top: -80px;
  margin-bottom: 22px;
  animation: ${fadeInPop} 500ms ease-out 1000ms 1 backwards;

  &::before {
    ${absoluteFill}
    content: '';
    border-radius: inherit;
    box-shadow: inset 0 0 0 3.5px #00cb96;
  }

  @media (min-width: 700px) and (min-height: 700px) {
    margin-top: 0;
  }

  @media (min-height: 1000px) {
    width: 100px;
    height: 100px;
    margin-bottom: 36px;

    &::before {
      box-shadow: inset 0 0 0 6px #00cb96;
    }
    & svg {
      margin-top: 4px;
      width: 48px;
      height: 48px;
    }
  }
`;

export const DetailHeading = styled(ModalHeading)`
  font-size: 24px;
  margin-bottom: 16px;
  text-align: center;
  padding: 0 24px;
  max-width: 300px;
  animation: ${fadeInPop} 700ms ease-out 1200ms 1 backwards;

  @media (min-width: 600px) {
    padding: 0;
    text-align: left;
    max-width: none;
  }

  @media (min-width: 700px) and (min-height: 700px) {
    font-size: 32px;
  }
`;

export const DetailMessage = styled.div`
  font-size: 19px;
  font-weight: 500;
  line-height: 1.2;
  text-align: center;
  max-width: 320px;
  color: ${({ theme }) => theme.colors.grey6};
  animation: ${fadeIn} 600ms linear 1750ms 1 backwards;

  @media (min-width: 500px) {
    max-width: 380px;
    font-size: 24px;
  }

  @media (min-width: 600px) {
    line-height: 1;
    padding: 0;
    text-align: left;
    max-width: none;
  }
`;

export const DoneButton = styled(PageButton)`
  background-color: #00cb96;
  color: ${({ theme }) => theme.colors.grey1};
  position: absolute;
  bottom: 17.5vh;
  max-width: calc(100vw - 100px);
  animation: ${slideUpFadeIn} 500ms ease-out 2000ms 1 backwards;

  @media (max-height: 700px) {
    bottom: 20vh;
  }
`;

export const CountdownMessage = styled.div`
  position: absolute;
  bottom: 12.5vh;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
  animation: ${fadeIn} 250ms linear 0ms 1 backwards;

  @media (max-height: 700px) {
    bottom: 14vh;
  }
`;
