import { transparentize } from 'polished';
import styled from 'styled-components';

import { withStyledSystem } from 'theme';

import type { BannerProps } from './Banner';
import type { StyledSystemProps } from 'theme';

export enum BannerPlacement {
  top = 'top',
  bottom = 'bottom',
  topRight = 'topRight',
  bottomRight = 'bottomRight',

  topLeft = 'topLeft',
  bottomLeft = 'bottomLeft',
}

type StyledBannerProps = StyledSystemProps & BannerProps;

function getPositioning({ placement }: StyledBannerProps) {
  const isBottom =
    placement === BannerPlacement.bottom ||
    placement === BannerPlacement.bottomLeft ||
    placement === BannerPlacement.bottomRight;

  const isRight =
    placement === BannerPlacement.topRight || placement === BannerPlacement.bottomRight;

  const isCenter = placement === BannerPlacement.top || placement === BannerPlacement.bottom;

  return {
    isBottom,
    isRight,
    isCenter,
    isTop: !isBottom,
    isLeft: !isRight,
  };
}

function getWrapPositionStyles({ placement }: StyledBannerProps) {
  const styles = {
    top: '0',
    bottom: 'auto',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  };

  const { isBottom, isRight, isCenter } = getPositioning({ placement });

  if (isBottom) {
    styles.top = 'auto';
    styles.bottom = '0';
    styles.alignItems = 'flex-end';
  }
  if (isRight) {
    styles.justifyContent = 'flex-end';
  }
  if (isCenter) {
    styles.justifyContent = 'center';
  }
  return styles;
}
export const BannerWrap = styled.div<StyledBannerProps>`
  position: fixed;
  left: 0;
  right: 0;
  z-index: 20;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  opacity: ${({ show }) => (show ? 1 : 0)};
  pointer-events: ${({ show }) => (show ? 'auto' : 'none')};
  transition: opacity ${({ show }) => (show ? 350 : 200)}ms linear 0ms;
  ${getWrapPositionStyles}
  ${withStyledSystem}
`;

function getBannerTransformStyles({ placement, show }: StyledBannerProps) {
  if (show) {
    return 'translate3d(0, 0, 0)';
  }
  const translate = {
    x: '-120%',
    y: '0',
  };

  const { isBottom, isRight, isCenter } = getPositioning({ placement });

  if (isCenter) {
    translate.x = '0';

    if (isBottom) {
      translate.y = '140%';
    } else {
      translate.y = '-140%';
    }
  }
  if (isRight) {
    translate.x = '120%';
  }

  return {
    transform: `translate3d(${translate.x}, ${translate.y}, 0)`,
  };
}

export const BannerBox = styled.div<StyledBannerProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  max-width: 592px;
  height: 88px;
  margin: 16px;
  background-color: ${({ theme }) => transparentize(0.1, theme.colors.grey1)};
  backdrop-filter: blur(5px);
  transition: transform ${({ show }) => (show ? 400 : 350)}ms ease 0ms;
  pointer-events: inherit;
  border-radius: 8px;
  box-shadow:
    0 8px 32px rgba(0, 0, 0, 0.12),
    0 22px 12px rgba(0, 0, 0, 0.02);

  @media (min-height: 900px) {
    margin-top: 32px;
  }

  @media (min-height: 1024px) {
    margin-top: 48px;
  }

  @media (min-width: 700px) {
    margin-left: 32px;
    margin-right: 32px;
  }
  ${getBannerTransformStyles}
  ${withStyledSystem}
`;
