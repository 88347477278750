import { useEffect } from 'react';

import storage from 'local-storage-fallback';
import { useHistory } from 'react-router-dom';

import { PageBox } from 'apps/kiosk/components';
import { VALIDATION_SHORT_CODE_STORAGE_KEY } from 'apps/kiosk/constants';

import { Message } from './MissingShortCodeContainer.styled';

export default function MissingShortCodeContainer() {
  const history = useHistory();

  useEffect(() => {
    const lastSetShortCode = storage.getItem(VALIDATION_SHORT_CODE_STORAGE_KEY);
    if (lastSetShortCode) {
      history.replace(`/${lastSetShortCode}`);
    }
  }, [history]);

  return (
    <PageBox>
      <Message>This kiosk is temporarily out of order.</Message>
    </PageBox>
  );
}
