import { useEffect, useState } from 'react';
import type { ReactNode } from 'react';

interface CountdownProps {
  /** Coundown duration in seconds */
  duration: number;

  /** Delay first countdown tick (milliseconds) */
  offsetStart?: number;
  onStart?: () => void;
  onComplete?: () => void;
  renderCountdown?: (remaining: number) => ReactNode;
}

export default function Countdown({
  duration,
  offsetStart = 0,
  onStart = () => {},
  onComplete = () => {},
  renderCountdown = () => null,
}: CountdownProps) {
  const [remaining, setRemaining] = useState(duration);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (remaining > 0) {
      const firstTick = remaining === duration;
      if (firstTick) {
        onStart();
      }
      timeoutId = setTimeout(
        () => setRemaining((prev) => prev - 1),
        firstTick ? 1000 + offsetStart : 1000,
      );
    } else {
      onComplete();
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [duration, offsetStart, onComplete, onStart, remaining]);
  return <>{renderCountdown(remaining)}</>;
}
