import type { ReactNode } from 'react';

import { AlertModalBox, AlertModalIconBox, AlertModalContent } from './AlertModal.styled';
import { ModalBackground } from './Modals.styled';

import type { BoxProps } from 'components/uikit';

interface AlertModalProps extends BoxProps {
  icon?: ReactNode;
  show?: boolean;
  onCancel?: () => void;
  children?: ReactNode;
}

export function AlertModal({
  icon,
  show = false,
  onCancel = () => {},
  children,
  ...modalContentProps
}: AlertModalProps) {
  return (
    <AlertModalBox show={show}>
      <ModalBackground onClick={onCancel} />
      <AlertModalContent show={show} {...modalContentProps}>
        {icon && <AlertModalIconBox>{icon}</AlertModalIconBox>}
        {children}
      </AlertModalContent>
    </AlertModalBox>
  );
}
