import { apiFetch } from 'utils/http';

export const ValidationService = {
  getValidationDetails: (shortCode: string) => apiFetch(`/kiosk/${shortCode}/details`),

  validateVisit: ({
    shortCode,
    licensePlateText,
  }: {
    shortCode: string;
    licensePlateText: string;
  }) =>
    apiFetch(`/kiosk/${shortCode}/validate`, {
      method: 'POST',
      body: {
        text: licensePlateText,
      },
    }),
};
