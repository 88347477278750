import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { withStyledSystem } from 'theme';

import type { StyledSystemProps } from 'theme';

const buttonStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  -webkit-user-select: none !important;
  user-select: none !important;
`;

export const Button = styled.button<StyledSystemProps>`
  ${buttonStyle}
  ${withStyledSystem}
`;

export const PageButton = styled.button<StyledSystemProps>`
  ${buttonStyle}
  width: 557px;
  max-width: 100%;
  height: 60px;
  border-radius: 10px;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.035em;
  text-rendering: optimizeLegibility;
  line-height: 49px;
  transition: opacity 100ms linear 0ms;

  @media (min-width: 700px) {
    height: 80px;
  }

  &:active {
    transition: none;
    opacity: 0.75;
  }

  &:disabled {
    opacity: 0.35;
  }
  ${withStyledSystem}
`;

export const InputButton = styled.button<StyledSystemProps>`
  ${buttonStyle}
  width: 88px;
  height: 88px;
  border-radius: 4px;
  ${withStyledSystem}
`;

export const LinkButton = styled(Link)<StyledSystemProps>`
  ${buttonStyle}
  ${withStyledSystem}
`;
