import { ReactComponent as TimerIcon } from 'apps/kiosk/assets/svg/timer.svg';

import { ValidationInfoBox } from './ValidationInfo.styled';

import type { KioskValidationDetail } from 'apps/kiosk/types';

type ValidationInfoProps = {
  validation: KioskValidationDetail | null;
};

export function ValidationInfo({ validation }: ValidationInfoProps) {
  if (validation === null) {
    return null;
  }
  return (
    <ValidationInfoBox>
      <TimerIcon />
      <span>
        {validation.validationTerms}
        &nbsp;&bull;&nbsp;
        {validation.name}
      </span>
    </ValidationInfoBox>
  );
}
